
import { defineComponent, onMounted } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { useRoute } from "vue-router";

export default defineComponent({
  name: "customer-dmp-plan",
  components: {},
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbs(
        "Draft Financial Resolution Proposal Plan (DMP)",
        ["Customer"]
      );
    });

    return {};
  },
  data() {
    let isLoading = true;
    const route = useRoute();
    const cstmr_id = route.params.id ?? null;
    const finResoUrl =
      "#/customers/details/" + cstmr_id + "/dmp-plan/financial-resolution/";

    return {
      finResoUrl,
    };
  },
  methods: {
    updateDMPProfiling(dmpProfile) {
      this.$emit("updateDMPProfiling", dmpProfile);
    },
  },
});
